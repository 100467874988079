import * as React from "react";
import { Button } from "../components/button";
import { Input } from "../components/input";

const RsvpPage = () => {
  const [name, setName] = React.useState("");
  const [rsvp, setRsvp] = React.useState<boolean | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<null | string>(null);
  const [success, setSuccess] = React.useState<null | string>(null);
  return (
    <div style={{ paddingBottom: 40 }}>
      <div
        style={{
          margin: "0 auto",
          maxWidth: 1080,
          paddingTop: 0,
          textAlign: "center",
          paddingRight: "20px",
          paddingLeft: "20px",
        }}
      >
        <form
          name="rsvp"
          style={{
            textAlign: "center",
            padding: 20,
            maxWidth: 500,
            marginLeft: "auto",
            marginRight: "auto",
          }}
          onSubmit={(event) => {
            event.preventDefault();
            setError(null);
            setSuccess(null);
            if (!name) {
              setError("Please fill in your name");
              return;
            }
            if (rsvp === undefined) {
              setError("Please select an RSVP option");
              return;
            }
            setLoading(true);
            fetch(
              "https://i7btwgv8mg.execute-api.eu-west-1.amazonaws.com/default/weddingFormResponse",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: JSON.stringify({
                  "form-name": "rsvp",
                  name,
                  rsvp: rsvp ? "true" : "false",
                }),
              }
            )
              .then((_res) => {
                setLoading(false);
                setName("");
                setRsvp(undefined);
                setSuccess("Thank you for your submission");
              })
              .catch((_err) => {
                setLoading(false);
                setError("Server error, please try again later");
              });
          }}
        >
          <p
            style={{
              fontFamily: "Raleway",
              fontWeight: 200,
              fontSize: "24pt",
              marginTop: 20,
            }}
          >
            RSVP
          </p>
          <Input
            label="Name"
            id="name"
            value={name}
            onChange={(event) => {
              const { value } = event.target;
              setName(value);
            }}
          />
          <div
            onClick={() => {
              setRsvp(true);
            }}
            style={{
              fontFamily: "Raleway",
              fontWeight: 200,
              fontSize: "16pt",
              padding: "20px",
            }}
          >
            <input
              type="checkbox"
              id="rsvp-yes"
              name="rsvp-yes"
              checked={rsvp === true}
              readOnly
            />
            <label style={{ margin: "10px" }}>Yes, i'll be there!</label>
          </div>
          <div
            onClick={() => {
              setRsvp(false);
            }}
            style={{
              fontFamily: "Raleway",
              fontWeight: 200,
              fontSize: "16pt",
              padding: "20px",
            }}
          >
            <input
              type="checkbox"
              id="rsvp-no"
              name="rsvp-no"
              checked={rsvp === false}
              readOnly
            />
            <label style={{ margin: "10px" }}>Sorry, I can't make it</label>
          </div>
          <Button disabled={loading}>
            {loading ? "Loading..." : "Submit RSVP"}
          </Button>
          {success && (
            <p
              style={{
                fontFamily: "Raleway",
                color: "#57BC07",
                fontWeight: 600,
                marginTop: 20,
              }}
            >
              {success}
            </p>
          )}
          {error && (
            <p
              style={{
                fontFamily: "Raleway",
                color: "#CE0000",
                fontWeight: 600,
                marginTop: 20,
              }}
            >
              {error}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default RsvpPage;
